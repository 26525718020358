<template>
  <v-container
    id="admin-notifications-view"
    fluid
    tag="section"
  >
    <material-card
      color="primary"
      icon="mdi-account-outline"
    >
      <template #title>
        Thông báo
      </template>
    </material-card>
  </v-container>
</template>

<script>
  export default {
    name: 'AdminNotifications',
    components: {
    },
    // Title page
    metaInfo: {
      titleTemplate: 'Admin Notifications',
    },
  }
</script>
